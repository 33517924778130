import { isEqual, split, uniq } from 'lodash';
import { applyPatch, flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { CancelToken } from 'axios';

import Error from '../models/Error';
import FullProduct from '../models/product/FullProduct';
import Product from '../models/Product';
import ProductSearchPaginator from '../models/ProductSearchPaginator';
import StatefulStore from '../models/StatefulStore';
import ProductSortSelectorMap from '../types/ProductSortOrderByMap';
import RequestState, { RequestStateType } from '../types/RequestState';
import createMSTPatch from '../util/createMSTPatch';
import { createErrorModel } from '../util/error';
import { paramsToQueryIdentifier } from '../util/query';
import { stringify } from '../util/queryString';

const LAST_VISITED_PRODUCTS_KEY = 'nh_last_visited_products';
const MAXIMUM_STORED_FULL_PRODUCTS = 3;
const MAXIMUM_STORED_QUERIES = 10;
const MAXIMUM_STORED_PRODUCTS = 500;

const ProductStore = StatefulStore.named('ProductStore')
  .props({
    fullProducts: types.optional(types.map(FullProduct), {}),
    fullProductStates: types.optional(types.map(RequestStateType), {}),
    productListState: types.optional(RequestStateType, RequestState.NONE),
    productPdfQueryResults: types.optional(types.map(RequestStateType), {}),
    productQueryResults: types.optional(types.map(ProductSearchPaginator), {}),
    productQueryStates: types.optional(types.map(RequestStateType), {}),
    products: types.optional(types.map(Product), {}),
    productSortOptions: types.frozen(ProductSortSelectorMap),
    productStates: types.optional(types.map(RequestStateType), {}),
    productVariationColumn: types.maybeNull(types.string),
    productVariationRow: types.maybeNull(types.string),
    selectedProductColumn: types.maybeNull(types.string),
    selectedProductRow: types.maybeNull(types.string),
    uploadedFiles: types.optional(
      types.array(
        types.model({
          id: types.string,
          files: types.array(types.string),
        })
      ),
      []
    ),
    uploadedFilesError: types.maybeNull(Error),
    uploadedFilesState: types.optional(RequestStateType, RequestState.NONE),
  })
  .actions((self) => {
    let source;
    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;
    const ifShoppingCenter = () =>
      getRoot(self).configStore.siteConfig.isShoppingCenter;
    const activeProductSortOptions = () =>
      split(
        getRoot(self).configStore.productList.productListSortSelectors,
        ','
      );
    const customerGroupId = () => getRoot(self).accountStore.getCustomerGroupId;

    const updateIndividualProducts = (products) => {
      products.forEach((product) => {
        let identifiersEqual = true;
        const oldProduct = self.products.get(product.id);
        if (oldProduct) {
          updateOldProduct(product, oldProduct, identifiersEqual);
        } else {
          self.products.set(product.id, product);
          self.filterByCustomerGroupVisibility(product.id);
        }
        self.productStates.set(product.id, RequestState.LOADED);
      });
    };

    const updateOldProduct = (product, oldProduct, identifiersEqual) => {
      self.filterByCustomerGroupVisibility(oldProduct.id);
      if (oldProduct.multi && product.multi && product.multi.children) {
        identifiersEqual = isEqual(
          oldProduct.multi.getChildIdentifiers(),
          product.multi.children.map((child) => child.id)
        );
      }

      if (identifiersEqual) {
        const patch = createMSTPatch(oldProduct, product);
        applyPatch(oldProduct, patch);
      }
    };

    return {
      afterCreate: () => {
        self.lastVisitedProducts = self.getLocalStorageLastVisitedIds();
      },
      gcProductQueries: () => {
        const queriesToDelete = [...self.productQueryResults.keys()].slice(
          0,
          self.productQueryResults.size - MAXIMUM_STORED_QUERIES
        );
        self.deleteQueries(queriesToDelete);
      },
      gcFullProducts: () => {
        const productsToDelete = [...self.fullProducts.keys()].slice(
          0,
          self.fullProducts.size - MAXIMUM_STORED_FULL_PRODUCTS
        );
        self.deleteFullProducts(productsToDelete);
      },
      gcProducts: () => {
        const referencedProducts = new Set();
        self.productQueryResults.forEach((paginator) => {
          paginator.data.forEach((product) => {
            referencedProducts.add(product.id);
          });
        });
        const productsToDelete = [...self.products.keys()]
          .filter((id) => !referencedProducts.has(id))
          .slice(0, self.products.size - MAXIMUM_STORED_PRODUCTS);
        self.deleteProducts(productsToDelete);
      },
      activeProductSortList: () => {
        const activeSortList = activeProductSortOptions();
        return activeSortList
          ? self.productSortOptions.filter(
              (ss) => activeSortList.indexOf(ss.sortIndex.toString()) > -1
            )
          : self.productSortOptions[0];
      },
      deleteQueries: (keys) => {
        keys.forEach((key) => {
          self.productQueryResults.delete(key);
          self.productQueryStates.delete(key);
        });
      },
      deleteProducts: (ids) => {
        ids.forEach((id) => {
          self.productStates.delete(id);
          self.products.delete(id);
        });
      },
      deleteFullProducts: (ids) => {
        ids.forEach((id) => {
          const productObj = self.fullProducts.get(id);
          if (productObj) {
            self.fullProductStates.delete(id);
            self.fullProducts.delete(id);
          }
        });
      },
      filterByCustomerGroupVisibility(productId, useFull = false) {
        const product = useFull
          ? self.fullProducts.get(productId)
          : self.products.get(productId);

        if (product && product.isMulti()) {
          const children = product.multi.children.filter((childProduct) =>
            childProduct.hasCustomerGroupVisibility(customerGroupId())
          );
          product.multi.setChildren(children);
        }
      },
      getLocalStorageLastVisitedIds: () => {
        return (
          JSON.parse(window.localStorage.getItem(LAST_VISITED_PRODUCTS_KEY)) ||
          []
        );
      },
      insertLastVisitedProduct: (productId) => {
        const localStorageIds = self.getLocalStorageLastVisitedIds();
        localStorageIds.unshift(productId);
        const uniqueIds = uniq(localStorageIds).slice(0, 20);

        window.localStorage.setItem(
          LAST_VISITED_PRODUCTS_KEY,
          JSON.stringify(uniqueIds)
        );
        self.lastVisitedProducts = uniqueIds;
      },
      loadProduct: flow(function* loadProduct(id, params = null) {
        // If we already have a query running, cancel it.
        if (source) {
          source.cancel();
        }
        source = CancelToken.source();

        // Backend is case-insensitive
        // and in case of mix of lowercase / uppercase it will return product with new id.
        let fixedId = id;
        self.setLoading(true);
        self.fullProductStates.set(id, RequestState.LOADING);

        try {
          const product = yield getEnv(self).apiWrapper.request(
            `${baseApi('products')}/${id}`,
            {
              cancelToken: source.token,
              ...params,
            }
          );

          self.fullProducts.set(product.id, product);
          self.filterByCustomerGroupVisibility(product.id, true);

          if (!isEqual(product.id, id)) {
            fixedId = product.id;
          }
        } catch (e) {
          self.setError(e);
          self.fullProductStates.set(id, RequestState.ERROR);
          throw e;
        }

        self.setLoading(false);
        self.fullProductStates.set(fixedId, RequestState.LOADED);
        source = null;

        return fixedId;
      }),
      loadProducts: flow(function* loadProducts(
        searchParameters,
        allSections = false,
        overrideUrl = null
      ) {
        const queryIdentifier = paramsToQueryIdentifier(searchParameters);
        // Skip the API call if we already have the data
        if (self.productQueryStates.get(queryIdentifier)) {
          return;
        }

        self.setLoading(true);
        self.productQueryStates.set(queryIdentifier, RequestState.LOADING);

        try {
          const queryUrl = `${overrideUrl || baseApi('products')}?${stringify(
            searchParameters
          )}`;
          const productSearchPaginator = yield getEnv(self).apiWrapper.request(
            queryUrl,
            {},
            {
              active_section: searchParameters.sections
                ? searchParameters.sections[0]
                : null,
            }
          );
          const products = productSearchPaginator.data;

          // Update individual products and statuses
          updateIndividualProducts(products);

          // Set current query results
          self.productQueryResults.set(queryIdentifier, {
            ...productSearchPaginator,
            data: products.map((product) => product.id),
          });
        } catch (e) {
          self.setError(e);
          self.productQueryStates.set(queryIdentifier, RequestState.ERROR);
          throw e;
        }

        self.setLoading(false);
        self.productQueryStates.set(queryIdentifier, RequestState.LOADED);
      }),
      updateProduct: (product) => {
        self.products.set(product.id, product);
        self.filterByCustomerGroupVisibility(product.id);
        self.productStates.set(product.id, RequestState.LOADED);
      },
      loadUploadedFiles: flow(function* loadUploadedFiles() {
        self.uploadedFilesState = RequestState.LOADING;

        try {
          self.uploadedFiles = yield getEnv(self).apiWrapper.request(
            `product-file-uploads`,
            {},
            { active_section: null }
          );
        } catch (e) {
          self.uploadedFilesError = createErrorModel(e);
          self.uploadedFilesState = RequestState.ERROR;
          throw e;
        }

        self.uploadedFilesState = RequestState.LOADED;
      }),
      getProductList: flow(function* getProductList() {
        self.productListState = RequestState.LOADING;

        let productList = [];

        try {
          productList = yield getEnv(self).apiWrapper.request(
            `product-lists/products`
          );
        } catch (e) {
          self.productListState = RequestState.ERROR;
          throw e;
        }

        self.productListState = RequestState.LOADED;
        return productList;
      }),
      getProductByAccessToken: (params) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .get(`product-lists/products`, { params });
      },
      saveProduct: (product) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`product-lists/add-product`, product);
      },
      deleteProduct: flow(function* deleteProduct(params) {
        self.productListState = RequestState.LOADING;
        try {
          yield getEnv(self)
            .apiWrapper.apiAxios()
            .delete(`product-lists/delete-product`, { params });
        } catch (e) {
          self.productListState = RequestState.ERROR;
          throw e;
        }
        self.productListState = RequestState.LOADED;
      }),
      sendProductListEmail: (productListEmail) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`product-lists/send`, productListEmail);
      },
      getProductPdf: (id, params = null) => {
        const activeId = !!id ? `/${id}` : ``;

        return getEnv(self)
          .apiWrapper.apiAxios()
          .post(`product-pdf${activeId}`, params);
      },
      setProductVariationColumn: (column) => {
        self.productVariationColumn = column;
      },
      getProductVariationColumn: () => {
        return self.productVariationColumn;
      },
      setProductVariationRow: (row) => {
        self.productVariationRow = row;
      },
      getProductVariationRow: () => {
        return self.productVariationRow;
      },
      setSelectedColumn: (column) => {
        self.selectedProductColumn = column;
      },
      setSelectedRow: (row) => {
        self.selectedProductRow = row;
      },
    };
  })
  .views((self) => ({
    getAnyProductById: (productId) => {
      /* TODO Also purchased will be fixed to apply also on multi_child products later */
      return self.fullProducts.get(productId) || self.products.get(productId);
    },
    getProductById: (activeProductId, ifGetActualProduct = true) => {
      const product = self.fullProducts.get(activeProductId);
      if (product && ifGetActualProduct) {
        return product.getActualProduct(activeProductId);
      }

      return self.fullProducts.get(activeProductId);
    },
  }));

export default ProductStore;
