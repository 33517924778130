import { Duration } from 'luxon';
import { getRoot, types } from 'mobx-state-tree';

import GlobalConfig from '../models/GlobalConfig';
import Site from '../models/Site';
import StatefulStore from '../models/StatefulStore';
import Paths from '../types/Paths';
import ProductListStyle from '../types/ProductListStyle';
import SiteType from '../types/Site';

const LOCAL_STORAGE_IN_STOCK_PRODUCTS_ONLY_KEY = 'inStockProductsOnly';
const LOCAL_STORAGE_PRODUCT_LIST_STYLE = 'productListStyle';

const ConfigStore = StatefulStore.named('ConfigStore')
  .props({
    globalConfig: types.maybeNull(GlobalConfig),
    storedInStockProductsOnly: types.maybeNull(types.string),
    storedProductListStyle: types.maybeNull(types.string),
    site: types.maybeNull(Site),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    storedInStockProductsOnly: window.localStorage.getItem(
      LOCAL_STORAGE_IN_STOCK_PRODUCTS_ONLY_KEY
    ),
    storedProductListStyle: window.localStorage.getItem(
      LOCAL_STORAGE_PRODUCT_LIST_STYLE
    ),
  }))
  .views((self) => {
    return {
      get account() {
        return {
          enableMyAccount: self.globalConfig.ENABLE_MY_ACCOUNT,
          disableLogin: self.globalConfig.DENY_LOGGING,
          isMoneyTransfer: self.globalConfig.ENABLE_MONEY_TRANSFER_ACCOUNT,
          showCustomerGroupName: self.globalConfig.SHOW_CUSTOMER_GROUP_NAME,
          allowAllClients:
            self.globalConfig.ALLOW_SALESPERSON_LOGIN_ALL_CLIENTS,
        };
      },
      get activeConfig() {
        const { sectionStore } = getRoot(self);
        const defaultConfig = self.globalConfig || {};
        return sectionStore && sectionStore.activeSection
          ? {
              ...defaultConfig,
              ...sectionStore.activeSection.configurations,
            }
          : defaultConfig;
      },
      get activateSections() {
        const { sectionStore } = getRoot(self);
        const hasSections = sectionStore && sectionStore.sections.length > 0;
        return self.activeConfig.ACTIVATE_SECTIONS && hasSections;
      },
      get advancedSearch() {
        const advancedSearch = self.globalConfig.PRODUCT_SEARCH_PLAN === '1';
        return {
          enabled: advancedSearch,
          popularSuggestions:
            advancedSearch && self.globalConfig.PRODUCT_SEARCH_POPULAR_SEARCHES,
          recentSearches:
            advancedSearch && self.globalConfig.PRODUCT_SEARCH_RECENT_SEARCHES,
        };
      },
      get analytics() {
        // Implement other analytics integrations here
        return {
          ga4: {
            enabled: self.globalConfig?.USE_GA4,
            id: self.globalConfig?.GA4_CUSTOMERS_ID,
          },
        };
      },
      get banner() {
        return {
          mainBanner: {
            fullWidth: self.activeConfig.MAIN_BANNER_FULL_WIDTH,
            height: Number(self.activeConfig.MAIN_BANNER_HEIGHT),
            withinContent: self.activeConfig.MAIN_BANNER_WITHIN_CONTENT,
            style: self.activeConfig.BANNER_CAROUSEL_MAIN_FOOTER_STYLE,
          },
          footerBanner: {
            height: Number(self.activeConfig.FOOTER_BANNER_HEIGHT),
            style: self.activeConfig.BANNER_CAROUSEL_MAIN_FOOTER_STYLE,
          },
          scent: {
            onTop: self.activeConfig.BANNER_SCENT_ON_TOP,
            pattern: self.activeConfig.BANNER_SCENT_PATTERN,
            positionX: self.activeConfig.BANNER_SCENT_HORIZONTAL_POSITION,
            repeat: self.activeConfig.BANNER_SCENT_REPEAT,
            shouldShowMobile: self.activeConfig.BANNER_SCENT_MOBILE_ENABLE,
          },
        };
      },
      get barcodeSearch() {
        return {
          enabled: self.globalConfig.PRODUCT_SEARCH_USE_BARCODE_SEARCH,
        };
      },
      get cart() {
        return {
          modal: {
            showImages: self.activeConfig.CART_SHOW_ADDED_PRODUCT_IMAGES,
            showPdfButton:
              self.activeConfig.MASTER_SETTING_FOR_PDF_PRINTING &&
              self.activeConfig.ENABLE_PDF_ON_CART_MODAL,
          },
          button: {
            simplifiedMobile: self.activeConfig.CART_SIMPLIFIED_MOBILE_BUTTON,
          },
          type:
            self.globalConfig.PRODUCT_ADDED_TO_CART_MODAL_STYLE === '0'
              ? 'MODAL'
              : 'POPOVER',
        };
      },
      get checkout() {
        return {
          checkoutPath: self.globalConfig.ENABLE_LITE_CHECKOUT
            ? Paths.LiteCheckout
            : Paths.Checkout,
        };
      },
      get configurationTexts() {
        return self.globalConfig.CONFIGURATION_TEXTS;
      },
      get contactUs() {
        return {
          enableAddress: self.globalConfig.CONTACT_ENTRY_ADDRESS,
          requireAddress: self.globalConfig.CONTACT_FORM_ADDRESS_NEEDED,
          requirePhone: self.globalConfig.CONTACT_ENTRY_PHONE,
        };
      },
      get cookieNotice() {
        return {
          enabled: self.globalConfig.SHOW_COOKIE_USAGE_NOTICE,
          infoPageId: self.globalConfig.COOKIE_PRIVACY_INFO_PAGE_ID,
        };
      },
      get customerService() {
        return {
          phone: self.activeConfig.INVOICE_TELEPHONE,
          open: self.activeConfig.CUSTOMER_SERVICE_OPEN,
          callCost: self.activeConfig.CUSTOMER_SERVICE_PRICE,
          email: self.activeConfig.STORE_OWNER_EMAIL_ADDRESS,
        };
      },
      get denyRobots() {
        return self.activeConfig.DENY_ROBOTS;
      },
      get defaultLocale() {
        return self.activeConfig.DEFAULT_LANGUAGE;
      },
      get defaultSection() {
        if (!self.site && self.site.section_id) {
          return null;
        }

        return self.site.section_id;
      },
      get facebookVerification() {
        return self.globalConfig.FACEBOOK_VERIFICATION_CONTENT;
      },
      get formValidation() {
        return {
          city: { minLength: Number(self.globalConfig.ENTRY_CITY_MIN_LENGTH) },
          email: {
            minLength: Number(self.globalConfig.ENTRY_EMAIL_ADDRESS_MIN_LENGTH),
          },
          firstName: {
            minLength: Number(self.globalConfig.ENTRY_FIRST_NAME_MIN_LENGTH),
          },
          lastName: {
            minLength: Number(self.globalConfig.ENTRY_LAST_NAME_MIN_LENGTH),
          },
          username: {
            minLength: Number(self.globalConfig.ENTRY_LOGIN_NAME_MIN_LENGTH),
          },
          password: {
            minLength: Number(self.globalConfig.ENTRY_PASSWORD_MIN_LENGTH),
          },
          postalCode: {
            minLength: Number(self.globalConfig.ENTRY_POSTCODE_MIN_LENGTH),
          },
          streetAddress: {
            minLength: Number(
              self.globalConfig.ENTRY_STREET_ADDRESS_MIN_LENGTH
            ),
          },
          telephone: {
            minLength: Number(self.globalConfig.ENTRY_TELEPHONE_MIN_LENGTH),
          },
        };
      },
      get frontpageTabOrder() {
        return self.activeConfig.FRONTPAGE_TABS_ORDER;
      },
      get frontpageText() {
        return self.globalConfig.FRONTPAGE_TEXT;
      },
      get frontpageTabMaxProducts() {
        return Number(self.activeConfig.FRONTPAGE_TABS_MAX_PRODUCTS);
      },
      get gclid() {
        return {
          enabled: self.activeConfig.ENABLE_EXTERNAL_CAMPAIGN_VISIT_TRACKING,
          expirationSeconds: Number(
            self.globalConfig.EXTERNAL_CAMPAIGNS_EXPIRY_TIME
          ),
        };
      },
      get gtm() {
        return {
          enabled: self.globalConfig.USE_GTM,
          containerId: self.globalConfig.GTM_CUSTOMERS_ID,
        };
      },
      get hideProductWithZeroQuantity() {
        return !self.globalConfig.PRODUCT_LIST_FILTER_BY_STOCK;
      },
      get infoPages() {
        return {
          productPurchaseGuide:
            self.globalConfig.SHOPPING_CENTER_BUYING_GUIDE_PAGE_ID,
          customerBenefits: self.globalConfig.VIP_CUSTOMER_BENEFITS_INFOPAGE_ID,
          entryLogin: self.globalConfig.ENTRY_LOGIN_INFO_PAGE_ID,
          cartErroModal: self.globalConfig.CART_ERROR_INFO_PAGE_ID,
        };
      },
      get inStockProductsOnly() {
        if (self.hideProductWithZeroQuantity) {
          return self.activeConfig.PRODUCT_LIST_FILTER_BY_STOCK_DEFAULT_STATE;
        }

        return self.storedInStockProductsOnly === null
          ? self.activeConfig.PRODUCT_LIST_FILTER_BY_STOCK_DEFAULT_STATE
          : self.storedInStockProductsOnly === 'true';
      },
      get integrations() {
        return {
          arinet: {
            enabled: self.globalConfig.ENABLE_ARINET_INTEGRATION,
          },
          criteo: {
            enabled: self.globalConfig.ENABLE_CRITEO_INTEGRATION,
            containerId: self.globalConfig.CRITEO_MERCHANT_ID,
          },
          facebookGraphAPI: {
            enabled: self.globalConfig.ENABLE_FACEBOOK_GRAPH_API,
          },
          facebookPixel: {
            enabled: self.globalConfig.ENABLE_FACEBOOK_TRACKING,
            id: self.globalConfig.FACEBOOK_PIXEL_ID,
          },
          giosg: {
            enabled: self.globalConfig.USE_GIOSG,
            merchantId: self.globalConfig.GIOSG_CUSTOMER_ID,
          },
          postAffiliate: {
            accountId: self.globalConfig.POSTAFFILIATE_ACCOUNT_ID,
            enabled: self.globalConfig.ENABLE_POSTAFFILIATE_INTEGRATION,
            url: self.globalConfig.POSTAFFILIATE_MERCHANT_URL,
          },
          serviceForm: {
            enabled: self.activeConfig.ENABLE_SERVICE_FORM_INTEGRATION,
          },
          spotmore: {
            enabled: self.activeConfig.ACTIVATE_SPOTMORE,
          },
          swogo: {
            enabled: self.globalConfig.ENABLE_SWOGO_INTEGRATION,
          },
          yotpo: {
            appKey: self.globalConfig.YOTPO_APP_KEY,
            enabled:
              self.globalConfig.ENABLE_YOTPO_INTEGRATION &&
              self.globalConfig.YOTPO_APP_KEY.length > 0,
          },
          zoovu: {
            enabled: self.globalConfig.ENABLE_ZOOVU_INTEGRATION,
          },
        };
      },
      get klarnaSupportedLocaleCurrencies() {
        return self.globalConfig.KLARNA_SUPPORTED_LOCALE_CURRENCIES;
      },
      get languageTexts() {
        return self.globalConfig.LANGUAGE_TEXTS;
      },
      get layout() {
        return {
          corners: {
            isRounded: self.globalConfig.LAYOUT_STORE_THEME_SELECTION === '1',
            isBoxed: self.globalConfig.LAYOUT_STORE_THEME_SELECTION === '2',
          },
        };
      },
      get orderHistory() {
        return {
          enableFilters: self.activeConfig.ENABLE_B2B_ORDER_HISTORY,
        };
      },
      get mainCategoryPage() {
        return {
          showMainProductList: self.activeConfig.SHOW_ALL_PRODUCTS_FROM_TOP,
          showProductTabs: self.activeConfig.PAGE_SUBCATEGORY_ENABLE_TABS,
          showCategoryGrid: self.activeConfig.SHOW_SUBCATEGORIES_ON_MAINCAT,
        };
      },
      get manufacturerBox() {
        return {
          style: self.activeConfig.BOX_MANUFACTURERS_STYLE,
          limitByActiveSection:
            self.activeConfig.BOX_MANUFACTURERS_LIMIT_TO_SECTION,
        };
      },
      get manufacturerListPage() {
        // TODO Needs a setting to backend. 0=original, 1=Only name, 2 = only logo
        return {
          style: self.site.type === SiteType.SHOPPING_CENTER ? '1' : '0',
        };
      },
      get mobileSettings() {
        return {
          showStoreLogo: self.globalConfig.SHOW_STORE_LOGO_ON_MOBILE,
          showAssistantMenu: self.globalConfig.SHOW_ASSISTANT_MENU_ON_MOBILE,
        };
      },
      get navigation() {
        return {
          common: {
            showCategorySlider: self.activeConfig.SHOW_MAIN_CATEGORY_SLIDER_TOP,
            useCategorySliderImages:
              self.activeConfig.CATEGORY_SLIDER_UI_SELECTION === '1',
            showCategoryList: self.activeConfig.SHOW_CATEGORY_LIST_BOTTOM,
            enableProductsMenu: self.activeConfig.SHOW_PRODUCTS_MENU_BUTTON,
          },
          sidebar: {
            enabled: self.activeConfig.SHOW_LEFT_COLUMN,
            showOnSale: self.globalConfig.SHOW_MAIN_LINK_SPECIAL_PRODUCTS,
            showNewProducts: self.globalConfig.SHOW_MAIN_LINK_NEW_PRODUCTS,
            showSoldProducts: self.globalConfig.SHOW_MAIN_LINK_SOLD_PRODUCTS,
            showMostSold: self.globalConfig.SHOW_MAIN_LINK_MOST_SOLD,
            showRecommended: self.globalConfig.SHOW_MAIN_LINK_RECOMMENDED,
            showFullWidthPageContent:
              self.activeConfig.SHOW_PAGES_100_PERCENT_WIDE,
          },
          megaMenu: {
            enabled: self.globalConfig.NAVIGATION_MENU_STYLE !== '0',
            forceTwoSubCategoryColumns:
              self.globalConfig.MEGAMENU_FORCE_TWO_PRODUCT_CATEGORY_COLUMNS,
            groupAdditionalLinks:
              self.globalConfig.MEGAMENU_GROUP_ADDITIONAL_LINKS,
            maximumSubCategoryCount:
              self.globalConfig.MEGAMENU_TWO_COLUMN_MAX_COUNT,
            showManufacturers: self.globalConfig.SHOW_MEGAMENU_MANUFACTURERS,
            twoLayerMegaMenuEnabled:
              self.globalConfig.NAVIGATION_MENU_STYLE === '2',
          },
        };
      },
      get newsletter() {
        return {
          enabled: self.activeConfig.ENABLE_NEWSLETTER_SUBSCRIPTION,
          requireName: self.activeConfig.CUSTOMER_NAME_NEEDED_ON_NEWSLETTER,
        };
      },
      get paymentModule() {
        return {
          klarna: {
            kreditorEid: self.globalConfig.MODULE_PAYMENT_KREDITOR_EID,
            kcoEid: self.globalConfig.MODULE_PAYMENT_KCO_EID,
          },
        };
      },
      get privacyStatement() {
        return {
          infoPageId: self.globalConfig.PRIVACY_STATEMENT_PAGE_ID,
        };
      },
      get product() {
        return {
          additionalProductTabsActive:
            self.activeConfig.ADDITIONAL_PRODUCT_TABS_ACTIVE,
          allowFileUploads: self.activeConfig.ALLOW_FILE_UPLOAD_FOR_CUSTOMERS,
          alwaysShowUnit: self.activeConfig.SHOW_ALWAYS_PRODUCT_PACKAGES,
          backorderLimit: Number(
            self.activeConfig.ORDER_ONLY_PRODUCT_ORDER_LIMIT
          ),
          enableProductWatcher: self.activeConfig.ENABLE_PRODUCTS_WATCHER,
          enableEnquiry: self.activeConfig.ENABLE_PRODUCT_PAGE_PRODUCT_ENQUIRY,
          enquiryForZeroPrice:
            self.activeConfig.PRODUCTS_PRICE_ZERO_IS_VIEW_ONLY,
          hideMultiProductSelectionImage:
            self.activeConfig.HIDE_MULTIPRODUCT_SELECTION_IMAGE,
          imageAspectRatio: self.activeConfig.PRODUCT_IMAGE_ASPECT_RATIO,
          isNewForSeconds: Duration.fromObject({
            days: Number(self.activeConfig.NEW_PRODUCTS_EXPIRARY_TIME),
          }).as('seconds'),
          multiproductAsDropdown: self.activeConfig.MULTIPRODUCT_AS_DROPDOWN,
          productQuantityDiscountDisplayStyle:
            self.activeConfig.PRODUCTS_QUANTITY_DISCOUNT_DISPLAY_STYLE,
          shortDescriptionTop:
            self.activeConfig.PRODUCT_PAGE_SHORT_DESCRIPTION_POSITION === 'TOP',
          shortDescriptionBottom:
            self.activeConfig.PRODUCT_PAGE_SHORT_DESCRIPTION_POSITION ===
            'BOTTOM',
          showModel: self.activeConfig.DISPLAY_PRODUCTS_MODEL,
          showSecondaryTaxPrice:
            self.activeConfig.PRODUCT_PAGE_SHOW_TAX_BREAKDOWN_FOR_CONSUMERS,
          showShortDescription:
            self.activeConfig.SHOW_PRODUCTS_SHORT_DESCRIPTION,
          showShippingDetails:
            self.activeConfig.PRODUCT_PAGE_SHOW_SHIPPING_DETAILS_TAB,
          showStoreAvailability: self.activeConfig.ENABLE_STORAGE_INFO,
          useSeoDescriptionForShort:
            self.activeConfig.USE_PRODUCT_LONG_DESCRIPTION_AS_SHORT_DESCRIPTION,
        };
      },
      get productPage() {
        return {
          apiAlsoPurchased: self.activeConfig.ACTIVATE_ALSO_PURCHASED_PRODUCTS,
          depositInPrice: self.activeConfig.ADD_BOTTLE_DEPOSIT_TO_PRICE,
          enableAdditionalServicesModal:
            self.activeConfig.PRODUCT_PAGE_ENABLE_ADDITIONAL_SERVICE_MODAL,
          hiddenProperties: self.activeConfig.NOT_VISIBLE_PROPERTIES,
          matrixDimensionSorting:
            self.activeConfig.MATRIX_DIMENSION_ORDER_BY_COUNT,
          matrixDetailedStockInfoEnabled:
            self.activeConfig.PRODUCT_MATRIX_STOCKS_INFO_ENABLED,
          shippingOnlyProduct:
            self.activeConfig.PRODUCT_PAGE_SHIPPING_OPTIONS_CALCULATION_TYPE ===
            '1',
          showAdditionalProductCode:
            self.activeConfig.PRODUCT_PAGE_SHOW_ADDITIONAL_PRODUCT_ID,
          showEanCode: self.activeConfig.PRODUCT_PAGE_SHOW_EAN_CODE,
          showExtraId: self.activeConfig.PRODUCT_PAGE_SHOW_EXTRA_ID,
          showManufacturerLogo:
            self.activeConfig.PRODUCT_PAGE_SHOW_MANUFACTURERS_LOGO,
          showManufacturersCode:
            self.activeConfig.PRODUCT_PAGE_SHOW_MANUFACTURERS_CODE,
          showMultiProductModel: self.activeConfig.DISPLAY_MULTI_PRODUCTS_MODEL,
          showPdfButton:
            self.activeConfig.MASTER_SETTING_FOR_PDF_PRINTING &&
            self.activeConfig.ENABLE_PDF_ON_PRODUCT_PAGE_CONTENT,
          showProductCode: self.activeConfig.PRODUCT_PAGE_SHOW_PRODUCT_CODE,
          showWarranty: self.activeConfig.PRODUCT_PAGE_SHOW_WARRANTY,
          showWeight: self.activeConfig.PRODUCT_PAGE_SHOW_WEIGHT,
          validateForCartQuery:
            self.activeConfig.PRODUCT_PAGE_VALIDATE_FOR_CART,
        };
      },
      get productCard() {
        return {
          showAddToCart: self.activeConfig.PRODUCT_LIST_CART_BUTTON,
          showEanCode: self.activeConfig.PRODUCT_LIST_EAN,
          showExtraId: self.activeConfig.PRODUCT_LIST_EXTRA_ID,
          showDescription: self.activeConfig.PRODUCT_LIST_DESCRIPTION,
          showProperties: self.activeConfig.SHOW_COLORS_AND_SIZES_ON_LISTING,
          showProductCode: self.activeConfig.PRODUCT_LIST_CODE,
          showQuantitySelection: self.activeConfig.PRODUCT_LIST_QUANTITY,
          showSingularPrice: self.activeConfig.PRODUCT_LIST_SINGULAR_PRICE,
          showManufacturersCode:
            self.activeConfig.PRODUCT_LIST_MANUFACTURERS_CODE,
        };
      },
      get productList() {
        return {
          defaultSortOption:
            self.activeConfig.DEFAULT_CATEGORY_PRODUCT_SORT_OPTION,
          filterProductsByText:
            self.activeConfig.PRODUCT_LIST_FILTER_BY_SEARCH_PHRASE,
          productListSortSelectors:
            self.activeConfig.PRODUCT_LIST_SORT_SELECTORS,
          priceSearchDisabled:
            self.activeConfig.PRODUCT_LIST_NO_CUSTOMER_GROUP_PRICE_SEARCH,
          showPdfButton:
            self.activeConfig.MASTER_SETTING_FOR_PDF_PRINTING &&
            self.activeConfig.ENABLE_PDF_ON_CATEGORY_PAGE,
          style:
            ProductListStyle[self.storedProductListStyle] ||
            (self.globalConfig.PRODUCT_LIST_CARD_DEFAULT_STYLE === '1'
              ? 'CARD_LARGE'
              : 'CARD'),
          productCountForEmail: Number(
            self.activeConfig.MAX_PRODUCTS_FOR_INSTANT_PDF_GENERATION
          ),
        };
      },
      get productSuggestions() {
        return {
          enabled: self.globalConfig.ENABLE_PRODUCT_SEARCH_SUGGESTIONS,
          count:
            self.globalConfig.ENABLE_PRODUCT_SEARCH_SUGGESTIONS &&
            self.globalConfig.PRODUCT_SEARCH_SUGGESTIONS_COUNT,
        };
      },
      get recaptcha() {
        return {
          enabled: self.globalConfig.CAPTCHA_ENABLE,
          sitekey: self.globalConfig.CAPTCHA_SITE_KEY,
        };
      },
      get registration() {
        return {
          enabled: self.globalConfig.ENABLE_CUSTOMER_REGISTRATION,
          isCompanyDefault: self.globalConfig.DEFAULT_CUSTOMER_TYPE,
          companyEnabled: self.globalConfig.ACCOUNT_COMPANY,
          requireBusinessId: self.globalConfig.SHOW_CUSTOMERS_VAT_NUMBER,
          quickRegistrationEnabled:
            self.globalConfig.ENABLE_QUICK_REGISTERATION,
          quickRegistrationRequirePhone:
            self.globalConfig.QUICK_REGISTERATION_PHONE_NEEDED,
        };
      },
      get recurringOrder() {
        return {
          enabled: self.activeConfig.ENABLE_RECURRING_ORDERS,
          infoPage: self.activeConfig.RECURRING_ORDERS_INFO_PAGE_ID,
        };
      },
      get reserveInStore() {
        return {
          enabled: self.globalConfig.ENABLE_RESERVE_IN_STORE,
          excludePropertyId: Number(
            self.globalConfig.RESERVE_IN_STORE_EXCLUDE_PROPERTY_ID
          ),
          expireTimeHours:
            self.globalConfig.RESERVE_IN_STORE_RESERVATION_EXPIRY_TIME,
        };
      },
      get reviews() {
        return {
          enabled: self.activeConfig.ALLOW_PRODUCT_REVIEWS,
          approvalRequired: self.activeConfig.PRODUCT_REVIEWS_REQUIRE_APPROVAL,
          allowedForVisitors:
            self.activeConfig.PRODUCT_REVIEWS_ALLOW_FOR_VISITORS,
          anonymous: self.activeConfig.PRODUCT_REVIEWS_ANONYMOUS,
        };
      },
      get sectionPage() {
        return {
          useProductList: self.activeConfig.SECTIONPAGE_USE_PRODUCT_LIST,
          singleRowTabs:
            self.activeConfig.SECTION_PAGE_SINGLE_ROW_TABS &&
            self.activeConfig.SECTIONPAGE_USE_PRODUCT_LIST,
          showPdfButton:
            self.activeConfig.MASTER_SETTING_FOR_PDF_PRINTING &&
            self.activeConfig.ENABLE_PDF_ON_SECTION_PAGE,
        };
      },
      get seo() {
        return {
          indexedParameters: self.globalConfig.PREVENT_NOINDEX_BY_PARAMETER,
        };
      },
      get shopAlike() {
        return {
          enabled: self.globalConfig.USE_SHOPALIKE_SERVICE,
          id: self.globalConfig.SHOPALIKE_TRACKING_ID,
        };
      },
      get siteConfig() {
        return {
          siteSectionId: self.site.section_id,
          siteType: self.site.type,
          isShoppingCenter: self.site.type === SiteType.SHOPPING_CENTER,
          isWebStore:
            self.site.type === SiteType.WEB_STORE ||
            self.site.type === SiteType.OFFER_REQUEST_SITE ||
            self.site.type === SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
          isStore:
            self.site.type === SiteType.WEB_STORE ||
            self.site.type === SiteType.SHOPPING_CENTER ||
            self.site.type === SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
          isHomePage: self.site.type === SiteType.HOME_PAGE,
          isOnlyProposal: self.site.type === SiteType.OFFER_REQUEST_SITE,
          isHybrid: self.site.type === SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
          proposalEnabled:
            self.site.type === SiteType.OFFER_REQUEST_SITE ||
            self.site.type === SiteType.WEB_STORE_WITH_OFFER_REQUESTS,
        };
      },
      get soldProductsPage() {
        return {
          showProductCards: self.globalConfig.SOLD_PRODUCTS_SHOW_PRODUCT_CARD,
        };
      },
      get store() {
        // General setting for the whole store
        return {
          loginRequired: self.globalConfig.REQUIRE_LOGIN_FOR_ENTRY,
          name: self.globalConfig.STORE_NAME,
        };
      },
      get storeInfoPageId() {
        return Number(self.globalConfig.STORE_INFO_PAGE_ID);
      },
      get suitableProducts() {
        return {
          enabledOnCategories:
            self.globalConfig.FIND_FROM_ACCESSORIES_CATEGORIES.map(Number),
        };
      },
      get termsOfUseStatement() {
        return {
          infoPageId: self.globalConfig.DELIVERYTERMS_PAGE_ID,
        };
      },
      get topNavigation() {
        return {
          addToCartRow: {
            desktop:
              self.activeConfig.NAVIGATION_ADD_TO_CART_ROW === '1' ||
              self.activeConfig.NAVIGATION_ADD_TO_CART_ROW === '3',
            mobile:
              self.activeConfig.NAVIGATION_ADD_TO_CART_ROW === '2' ||
              self.activeConfig.NAVIGATION_ADD_TO_CART_ROW === '3',
          },
          headerMobileSearchbarVisibility:
            self.globalConfig.HEADER_MOBILE_SEARCHBAR_VISIBILITY,
          headerMobileSectionSlider:
            self.globalConfig.HEADER_MOBILE_SECTION_SLIDER,
          center: self.globalConfig.HEADER_CENTER,
          centerShopLogo:
            self.globalConfig.HEADER_SEARCH_BAR_MOVE_TOP_NAVIGATION &&
            self.globalConfig.HEADER_SHOPPING_CART_MOVE_LOGIN_RIGHT_SIDE &&
            self.globalConfig.HEADER_CENTER_SHOP_LOGO,
          hideHeaderOnScrollingDown:
            self.globalConfig.HEADER_HIDE_STICKY_HEADER_ON_SCROLLING_DOWN,
          moveSearchbarTopNavigation:
            self.globalConfig.HEADER_SEARCH_BAR_MOVE_TOP_NAVIGATION,
          moveShoppingCartLoginRight:
            self.globalConfig.HEADER_SHOPPING_CART_MOVE_LOGIN_RIGHT_SIDE,
          useStickyHeaderOnStartup:
            self.globalConfig.HEADER_SHOW_STICKY_HEADER_ON_STARTUP,
        };
      },
      get useImageDescriptionAsAlt() {
        return self.activeConfig.USE_IMAGE_DESCRIPTION_ALT_TEXT;
      },
      get vehiclePartSearch() {
        return {
          enabled:
            self.globalConfig.ACTIVE_VEHICLE_PART_SEARCH_INTEGRATION === '1',
        };
      },
      get visibility() {
        /* Settings concerning whole webshop */
        return {
          availability:
            self.site.type !== SiteType.OFFER_REQUEST_SITE ||
            self.globalConfig.PROPOSAL_REQUEST_SHOW_DELIVERY_AND_QUANTITY,
          footer: {
            useMobileColumns: self.activeConfig.FOOTER_MOBILE_COLUMNS,
            showManufacturers: self.activeConfig.SHOW_FOOTER_MANUFACTURERS,
          },
        };
      },
      get wishlist() {
        return {
          enabled: self.activeConfig.SHOW_WISHLIST,
          enableEmailShare: self.globalConfig.ENABLE_WISHLIST_EMAIL_SHARE,
        };
      },
    };
  })
  .actions((self) => ({
    setup: (globalConfig, site) => {
      self.globalConfig = globalConfig;
      self.site = site;
      self.setLoading(false);
    },
    setInStockProductsOnly: (inStockProductsOnly) => {
      const storedValue = String(inStockProductsOnly);
      // Persist the configuration
      window.localStorage.setItem(
        LOCAL_STORAGE_IN_STOCK_PRODUCTS_ONLY_KEY,
        storedValue
      );
      self.storedInStockProductsOnly = storedValue;
    },
    setProductListStyle: (productListStyle) => {
      const storedValue = String(productListStyle);
      // Persist the configuration
      window.localStorage.setItem(
        LOCAL_STORAGE_PRODUCT_LIST_STYLE,
        storedValue
      );
      self.storedProductListStyle = storedValue;
    },
  }));

export default ConfigStore;
