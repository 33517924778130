import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import globalTranslations from '../../../../i18n/globalTranslations';
import { modelOf } from '../../../../prop-types';
import AccountStore from '../../../../store/AccountStore';
import ConfigStore from '../../../../store/ConfigStore';
import ProductClass from '../../../../types/ProductClass';
import ProductGroupSchema from '../ProductGroupSchema';
import ProductSchema from '../ProductSchema';
import FullProduct from '../../../../models/product/FullProduct';

/**
 * Use BaseSchema to build additional schemas.
 * This should only include general data that all schema types use.
 * For specific schema type, use existing ones or create new schema-component.
 */
const ProductBaseSchema = ({ accountStore, configStore, intl, product }) => {
  const hideReviews = accountStore.isViewOnly;

  const baseSchema = {
    '@context': 'https://schema.org/',
    '@type': null,
    description: product.description_short || product.seoDescription,
  };

  if (product.manufacturer) {
    baseSchema.brand = {
      '@type': 'Brand',
      name: product.manufacturer.name,
    };
  }

  const getProductReviewsSchema = useCallback(() => {
    return product.reviews.map((review) => ({
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: review.customers_name,
      },
      datePublished: DateTime.fromISO(review.data_added).toISODate(),
      reviewBody: review.description.reviews_text,
      reviewRating: [
        {
          '@type': 'Rating',
          ratingValue: review.reviews_rating,
          worstRating: 1,
          bestRating: 5,
          reviewAspect: intl.formatMessage(
            globalTranslations.reviewProductRatingTitle
          ),
        },
        {
          '@type': 'Rating',
          ratingValue: review.service_rating,
          worstRating: 1,
          bestRating: 5,
          reviewAspect: intl.formatMessage(
            globalTranslations.reviewServiceRatingTitle
          ),
        },
      ],
    }));
  }, []);

  if (
    configStore.reviews.enabled &&
    !hideReviews &&
    !configStore.integrations.yotpo.enabled &&
    product.reviews.length
  ) {
    baseSchema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: product.reviews_average,
      reviewCount: product.reviews_count,
    };

    baseSchema.review = getProductReviewsSchema();
  }

  const getSchema = () => {
    if (product.class === ProductClass.MULTI) {
      return <ProductGroupSchema baseSchema={baseSchema} product={product} />;
    }

    return <ProductSchema baseSchema={baseSchema} product={product} />;
  };

  return getSchema();
};

ProductBaseSchema.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
  product: modelOf(FullProduct).isRequired,
};

export default injectIntl(
  inject('accountStore', 'configStore')(observer(ProductBaseSchema))
);
