import RequestState from '../../types/RequestState';

/**
 *
 * @param {Object} props
 * @param {ProductStore} props.productStore
 * @param {Object} props.params
 * @param {Object} props.params.id
 * @param {Object} props.params.urlParams
 * @param {String} props.params.state
 */
const useFetchData = ({ productStore, params }) => {
  // The default value for state is not set
  if (!params.state || params.state === RequestState.NONE) {
    productStore
      .loadProduct(params.id, params.urlParams)
      .then((fixedId) => {
        productStore.insertLastVisitedProduct(fixedId);
      })
      .catch((e) => {
        // Don't make error out of 404 product api response,
        // so sentry won't pick it up.
        if (e.response && e.response.status !== 404) {
          console.error(e);
        }
      });
  }
};

export default useFetchData;
